/** @format */

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { FaPlay } from "react-icons/fa";

const Documentary = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    scrollToTop();
  }, []);
  const backToHome = () => {
    window.history.back();
  };
  const videoData = [  
    {
      title: "Aaron Jack",
      description:
        "How “Overemployed” Programmers Are Earning Multiple FULL TIMESalaries",
      posterUrl: "https://i.ytimg.com/vi/oR-mzzIsHVE/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/oR-mzzIsHVE?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Tech Stories",
      description:
        "Generalized AI Is Almost Here (This Will Change Everything)",
      posterUrl: "https://i.ytimg.com/vi/uPXmLKYPGzg/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/uPXmLKYPGzg?autoplay=1&rel=0&controls=0&showinfo=0",
    },

    {
      title: "Tech Stories",
      description: "It's Time to Start Worrying About North Korean Hackers",
      posterUrl: "https://i.ytimg.com/vi/VvSIobRXhUs/hqdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/VvSIobRXhUs?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Aaron Jack",
      description: "The Linux Political Spectrum",
      posterUrl: "https://i.ytimg.com/vi/uca3Ggg_2lg/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/uca3Ggg_2lg?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Aaron Jack",
      description:
        "Why Most Google Employees Quit After 1.1 Years (On Average)",
      posterUrl: "https://i.ytimg.com/vi/IZCfP13twxk/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/IZCfP13twxk?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    // {
    //   title: "",
    //   description: "",
    //   posterUrl: "",
    //   trailerUrl: "",
    // },
  ];
  return (
    <>
      <div className='container-fluid sec-bg2 pt-5'>
        <div className='carousel-container'>
          <div className='carousel'>
            <div className='slider  '>
              <div className='slide-content '>
                <h1 className='movie-title '>Documentary</h1>
              <div className='header-btn   backIcon'>
              <a
                    onClick={backToHome}
                    className='btn-default btn-border btn-opacity'>
                    <BsArrowLeft className='fs-4 text-light mx-2' />
                    <span>Back to Home page</span>
                  </a>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* cards */}
      <div className='container-fluid mb-5 pb-5'>
        <div className='row '>
          {videoData.map((item, index) => {
            return (
              <div className='col-lg-4 ' key={index}>
                <span
                  id='single_image'
                  data-fancybox
                  data-type='iframe'
                  style={{ cursor: "pointer" }}
                  data-src={item.trailerUrl}>
                  <div
                    className='movie-card'
                    style={{
                      backgroundImage: `url(${item.posterUrl})`,
                    }}>
                    {/* <div className='movie-card__overlay' />
                    <div className='movie-card__share'>
                      <button className='movie-card__icon'>
                        <i className='material-icons'></i>
                      </button>
                      <button className='movie-card__icon'>
                        <i className='material-icons'></i>
                      </button>
                      <button className='movie-card__icon'>
                        <i className='material-icons'></i>
                      </button>
                    </div> */}
                    {/* <div className='movie-card__content mt-4'>
                      <div className='movie-card__header'>
                        <h1 className='movie-card__title'>{item.title}</h1>
                        <h4 className="movie-card__info">(1982) Sci-Fi, Thriller</h4>
                      </div>
                      <p className='movie-card__desc'>{item.description}</p>

                    </div> */}
                     <div className='button is-play'>
                      <div className='button-outer-circle has-scale-animation' />
                      <div className='button-outer-circle has-scale-animation has-delay-short' />
                      <div className='button-icon is-play'>
                        <svg height='100%' width='100%' fill='#fff'>
                          <polygon
                            className='triangle'
                            points='5,0 30,15 5,30'
                            viewBox='0 0 30 15'
                          />
                          <path
                            className='path'
                            d='M5,0 L30,15 L5,30z'
                            fill='none'
                            stroke='#fff'
                            strokeWidth={1}
                          />
                        </svg>
                      </div>
                    </div>

                    {/* <button
                      className='btn btn-outline movie-card__button'
                      type='button'>
                      <FaPlay className='btn-icon' /> Watch Now
                    </button> */}
                  </div>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Documentary;
