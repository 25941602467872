/** @format */

import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./App.scss";
import Home from "./pages/Home";
import PageNotFound from "./pages/PageNotFound";
import Base from "./components/layout/Base";
import Documentary from "./pages/Documentary";
import Ads from "./pages/Ads";
import Reels from "./pages/Reels";
import Talk from "./pages/Talk";
import Business from "./pages/Business";
import Vlogs from "./pages/Vlogs";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Podcast from "./pages/Podcast";
function App() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  });
  return (
    <BrowserRouter>
      <Base>
        <Routes>
          <Route index path='/' element={<Home />} />
          <Route path='/Documentary' element={<Documentary />} />
          <Route path='/short-ads' element={<Ads />} />
          <Route path='/reels' element={<Reels />} />
          <Route path='/talking-head' element={<Talk />} />
          <Route path='/business' element={<Business />} />
          <Route path='/vlogs' element={<Vlogs />} />
          <Route path='/podcast' element={<Podcast />} />
          <Route path='/*' element={<PageNotFound />} />
        </Routes>
      </Base>
    </BrowserRouter>
  );
}

export default App;
