/** @format */

import React from "react";
import { Link } from "react-router-dom";

const CTA = () => {
  return (

    <div className='relative bg-gradient-to-r from-purple-900 to-indigo-800 py-16 container rounded-lg my-4 podcast'>
      <div className='absolute inset-0'>
        <img
          src='assets/cta.jpg'
          alt='Background'
          className='w-full h-full object-cover opacity-50 rounded-lg'
        />
      </div>
      <div className='relative max-w-screen-lg mx-auto px-8 z-10 text-center text-white rounded-lg'>
        <h1 className='text-4xl md:text-5xl font-extrabold text-light tracking-widest leading-tight mb-6'>
         Podcast
        </h1>
        {/* <p className='text-lg md:text-xl mb-8'>
          Experience excellence like never before with our exclusive podcast.
        </p> */}
        <Link to='podcast' className='btn-default btn-border btn-opacity max-w-48 m-auto'>
          <span>Check it out</span>
        </Link>
      </div>
    </div>
  );
};

export default CTA;
