/** @format */

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { FaPlay } from "react-icons/fa";

const Talk = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);
  const backToHome = () => {
    window.history.back();
  };
  const videoData = [
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/uOgyFsK1dsQ/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/uOgyFsK1dsQ?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/b_r-3o7TBcg/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/b_r-3o7TBcg?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/-UPWcbTXJ-k/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/-UPWcbTXJ-k?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/trEIhkFOtzA/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/trEIhkFOtzA?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/sJNPQ3128qc/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/sJNPQ3128qc?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/Y1MGmyaYWcI/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/Y1MGmyaYWcI?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/A1IOZUCTOkM/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/A1IOZUCTOkM?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/twwQM9mfuIs/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/twwQM9mfuIs?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/CXFb-w0welc/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/CXFb-w0welc?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/_Jj-O-JxoCg/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/_Jj-O-JxoCg?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/MIW80obK2pM/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/MIW80obK2pM?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/lNmvaTVTMeE/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/lNmvaTVTMeE?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/rtrwKfxcSa8/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/rtrwKfxcSa8?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/pxC13DdpGwg/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/pxC13DdpGwg?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/y05A9ZcokO0/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/y05A9ZcokO0?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/2SSycLfrKnI/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/2SSycLfrKnI?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/AGQRY3SqY7M/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/AGQRY3SqY7M?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/RCBkWU2ov4I/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/RCBkWU2ov4I?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/PVKX-a8TtlM/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/PVKX-a8TtlM?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/Ub_zNJIT3jI/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/Ub_zNJIT3jI?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/ZeCA371ckag/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/ZeCA371ckag?autoplay=1&rel=0&controls=0&showinfo=0",
    },


    {
      title: "CourseRanks",
      description:
        "Scott Hilse's FREE Dropshipping Course (Was $500!) Worth It?",
      posterUrl: "https://i.ytimg.com/vi/uEgPeSf8zb0/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/uEgPeSf8zb0?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Tech With Soleyman",
      description: "How I Learned To Code in 3 Months and Got a Job",
      posterUrl: "https://i.ytimg.com/vi/D7Hv2bMhZ5g/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/D7Hv2bMhZ5g?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Tech With Soleyman",
      description: "How I Passed My AWS Cloud Practitioner Exam in 7 Days",
      posterUrl: "https://i.ytimg.com/vi/s8byh4MCJYw/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/s8byh4MCJYw?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Tech With Soleyman",
      description:
        "From Beginner to Software Engineer | My Full Programming Journey",
      posterUrl: "https://i.ytimg.com/vi/2dMNvjpEu_o/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/2dMNvjpEu_o?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Trevin Peterson",
      description: "Andrew Tate - The DARK TRUTH of Hustler's University 2.0",
      posterUrl: "https://i.ytimg.com/vi/xrOERswzyKI/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/xrOERswzyKI?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Zach Ginn",
      description:
        "How to Overcome the Objection of Being a New or Young Wholesaler | Wholesaling Real Estate",
      posterUrl: "https://i.ytimg.com/vi/6mZ8T2Vwha8/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/6mZ8T2Vwha8?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Aaron Jack",
      description: "Avoid These HTML & CSS Mistakes At All Costs",
      posterUrl: "https://i.ytimg.com/vi/8FlWUa04f2g/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/8FlWUa04f2g?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Aaron Abke",
      description:
        "The Science of Mystical Experiences // Spiritual Intelligence 05",
      posterUrl: "https://i.ytimg.com/vi/95Xxtc8C6xY/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/95Xxtc8C6xY?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Frederique Bros",
      description: "How To Pack 21 OUTFITS in ONE Suitcase",
      posterUrl: "https://i.ytimg.com/vi/I1k4t2ZTAqI/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/I1k4t2ZTAqI?autoplay=1&rel=0&controls=0&showinfo=0",
    },
  ];

  return (
    <>
      <div className='container-fluid sec-bg2 pt-5'>
        <div className='carousel-container'>
          <div className='carousel'>
            <div className='slider  '>
              <div className='slide-content '>
                <h1 className='movie-title '>Talking Head</h1>
                <div className='header-btn   backIcon'>
                  <a
                    onClick={backToHome}
                    className='btn-default btn-border btn-opacity'>
                    <BsArrowLeft className='fs-4 text-light mx-2' />
                    <span>Back to Home page</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* cards */}
      <div className='container-fluid mb-5 pb-5'>
        <div className='row  '>
          {videoData.map((item, index) => {
            return (
              <div className='col-lg-4 ' key={index}>
                <span
                  id='single_image'
                  data-fancybox
                  data-type='iframe'
                  data-src={item.trailerUrl}>
                  <div
                    className='movie-card'
                    style={{
                      backgroundImage: `url(${item.posterUrl})`,
                    }}>
                  <div className='button is-play'>
                      <div className='button-outer-circle has-scale-animation' />
                      <div className='button-outer-circle has-scale-animation has-delay-short' />
                      <div className='button-icon is-play'>
                        <svg height='100%' width='100%' fill='#fff'>
                          <polygon
                            className='triangle'
                            points='5,0 30,15 5,30'
                            viewBox='0 0 30 15'
                          />
                          <path
                            className='path'
                            d='M5,0 L30,15 L5,30z'
                            fill='none'
                            stroke='#fff'
                            strokeWidth={1}
                          />
                        </svg>
                      </div>
                    </div>

                    {/* <button
                      className='btn btn-outline movie-card__button'
                      type='button'>
                      <FaPlay className='btn-icon' /> Watch Now
                    </button> */}
                  </div>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Talk;
