/** @format */

import React from "react";
import { Link } from "react-router-dom";

const Portfolio = () => {
  return (
    <div className='portfolio-area ptb--80 bg_color--1'>
      <div className='portfolio-wrapper' id='watch'>
        <div className='container px-0'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='section-title text-center'>
                <span className='subtitle'>My Portfolio</span>
                <h2 className='title'>Some of our Recent Works</h2>
                <p className='description'>
                  Positive experiences and the successful outcomes of their
                  Works.
                </p>
              </div>
            </div>
          </div>
          <div className='row mt--30'>
            <div className='col-lg-4 col-md-6 col-sm-6 col-12 mt--30'>
              <div className='im_portfolio' data-aos='fade-right'>
                <div className='thumbnail_inner'>
                  <div className='thumbnail'>
                    <Link to='/Documentary'>
                      <img src='assets/Documentary.jpg' alt='Documentary' />
                    </Link>
                  </div>
                </div>
                <div className='content'>
                  <div className='inner'>
                    <div className='portfolio_heading'>
                      <div className='category_list'>
                        {/* <Link to='/Documentary'>Documentary</Link> */}
                      </div>
                      <h4 className='title'>
                        <Link to='/Documentary'>Documentary</Link>
                      </h4>
                    </div>
                    <div className='portfolio_hover'>
                      <p></p>
                    </div>
                  </div>
                </div>
                <Link className='transparent_link' to='/Documentary' />
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-6 col-12 mt--30'>
              <div className='im_portfolio ' data-aos='fade-up'>
                <div className='thumbnail_inner'>
                  <div className='thumbnail'>
                    <Link to='/short-ads'>
                      <img src='assets/ads.jpg' alt='ads' />
                    </Link>
                  </div>
                </div>
                <div className='content'>
                  <div className='inner'>
                    <div className='portfolio_heading'>
                      <div className='category_list'>
                        {/* <Link to='/short-ads'>Ads</Link> */}
                      </div>
                      <h4 className='title'>
                        <Link to='/short-ads'>Short Ads</Link>
                      </h4>
                    </div>
                    <div className='portfolio_hover'>
                      <p></p>
                    </div>
                  </div>
                </div>
                <Link to='/short-ads' className='transparent_link' />
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-6 col-12 mt--30'>
              <div className='im_portfolio ' data-aos='fade-left'>
                <div className='thumbnail_inner'>
                  <div className='thumbnail'>
                    <Link to='/reels'>
                      <img src='assets/reels.jpg' alt='Reels' />
                    </Link>
                  </div>
                </div>
                <div className='content'>
                  <div className='inner'>
                    <div className='portfolio_heading'>
                      <div className='category_list'>
                        {/* <Link to='/reels'>Reels</Link> */}
                      </div>
                      <h4 className='title'>
                        <Link to='/reels'>Reels</Link>
                      </h4>
                    </div>
                    <div className='portfolio_hover'>
                      <p></p>
                    </div>
                  </div>
                </div>
                <Link className='transparent_link' to='/reels' />
              </div>
            </div>
          </div>

          <div className='row mt--20'>
            <div
              className='col-lg-4 col-md-6 col-sm-6 col-12 mt--30'
              data-aos='fade-right'>
              <div className='im_portfolio'>
                <div className='thumbnail_inner'>
                  <div className='thumbnail'>
                    <Link to='/talking-head'>
                      <img src='assets/talk.jpg' alt='Documentary' />
                    </Link>
                  </div>
                </div>
                <div className='content'>
                  <div className='inner'>
                    <div className='portfolio_heading'>
                      <div className='category_list'>
                        {/* <Link to='/talking-head'>Talk</Link> */}
                      </div>
                      <h4 className='title'>
                        <Link to='/talking-head'>Talking Head</Link>
                      </h4>
                    </div>
                    <div className='portfolio_hover'>
                      <p></p>
                    </div>
                  </div>
                </div>
                <Link className='transparent_link' to='/talking-head' />
              </div>
            </div>
            <div
              className='col-lg-4 col-md-6 col-sm-6 col-12 mt--30'
              data-aos='fade-up'>
              <div className='im_portfolio'>
                <div className='thumbnail_inner'>
                  <div className='thumbnail'>
                    <Link to=''>
                      <img src='assets/busniss.jpg' alt='ads' />
                    </Link>
                  </div>
                </div>
                <div className='content'>
                  <div className='inner'>
                    <div className='portfolio_heading'>
                      <div className='category_list'>
                        {/* <Link to='/business'>Business</Link> */}
                      </div>
                      <h4 className='title'>
                        <Link to='/business'>Business</Link>
                      </h4>
                    </div>
                    <div className='portfolio_hover'>
                      <p></p>
                    </div>
                  </div>
                </div>
                <Link to='/business' className='transparent_link' />
              </div>
            </div>
            <div
              className='col-lg-4 col-md-6 col-sm-6 col-12 mt--30 '
              data-aos='fade-left'>
              <div className='im_portfolio'>
                <div className='thumbnail_inner'>
                  <div className='thumbnail'>
                    <Link to=''>
                      <img src='assets/vlog.jpg' alt='Reels' />
                    </Link>
                  </div>
                </div>
                <div className='content'>
                  <div className='inner'>
                    <div className='portfolio_heading'>
                      <div className='category_list'>
                        {/* <Link to='/vlogs'>Vlogs</Link> */}
                      </div>
                      <h4 className='title'>
                        <Link to='/vlogs'>Vlogs</Link>
                      </h4>
                    </div>
                    <div className='portfolio_hover'>
                      <p></p>
                    </div>
                  </div>
                </div>
                <Link className='transparent_link' to='/vlogs' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
